import React, { useEffect } from "react";

const Home = () => {
    // useEffect(() => {
    //     window.location.replace('https://portal-staging.xceptionallearningindia.com/');
    // }, [])
    
  return (
  <div>
    {/* <h1>Xceptional Learning Meet</h1> */}
  </div>);
};

export default Home;
