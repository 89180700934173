import React, { useState } from 'react';
import './ContentPaginationStyles.css';
const ContentPagination = ({ 
    pageCount,
    handlePageClick,
    currentPage
}) => {
  return (
    <div className="pagination-container">
        <ul className="pagination">
        {Array.from({ length: pageCount }, (_, index) => (
            <li
            key={index + 1}
            className={`pagination-item ${
                currentPage === index + 1 ? 'active' : ''
            }`}
            onClick={() => {handlePageClick(index + 1);}}
            >
            {index + 1}
            </li>
        ))}
        </ul>
    </div>
  );
};

export default ContentPagination;