import React, { useRef, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";
import { Buffer } from "buffer";
import axios from "axios";
import "./style.css";
import { JITSIDOMAIN, API, HB_API_ROOT, PORTAL_ROOT } from "./config";
import Sidebar from "./components/Sidebar";
import Hyperbeam from "https://unpkg.com/@hyperbeam/web@latest/dist/index.js";
import { Modal } from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';

const Room = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const meetingId = searchParams.get("meeting_id");
  const meetingName = searchParams.get("meeting_name");
  const authHeader = searchParams.get("authHeader");
  const user_name = searchParams.get("user_name");

  const [isLoading, setIsLoading] = useState(true);
  const [isValidMeet, setIsValidMeet] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [userName, setUserName] = useState(user_name || "No-name-user");

  const accessToken = authHeader ? 
  Buffer.from(authHeader, "base64").toString("utf-8") : null;

  const apiRef = useRef();
  const [logItems, updateLog] = useState([]);
  const [showNew, toggleShowNew] = useState(false);
  const [knockingParticipants, updateKnockingParticipants] = useState([]);
  const [isHangupModalVisible, setIsHangupModalVisible] = useState(false);
  const [leaveMeetingAction, setLeaveMeetingAction] = useState("hangup");
  const [isVideoContentShared, setIsVideoContentShared] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCollaborate, setIsCollaborate] = useState(false);
  const [hbComputer, setHbComputer] = useState(null);
  const [hbEmbed, setHbEmbed] = useState(null);
  const [hbSessionLoading, setHbSessionLoading] = useState(false);
  const [moderatorFromApi, setModeratorFromApi] = useState(false);
  const [localParticipantId, setLocalParticipantId] = useState(null);
  const [showWaitForModerator, setShowWaitForModerator] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    isValidToken()
      .then((response) => {
        const data = response.data;
        if(data.meetingStatus?.toLowerCase() !== "cancelled"){
          if(data.userName && data.userName.length > 0 && data.userRole === "moderator") {
            setUserName(response.data.userName);
          }
          setIsModerator(response.data.userRole === "moderator" ? true : false);
          setModeratorFromApi(response.data.userRole === "moderator" ? true : false);
          setIsLoading(false);
          setIsValidMeet(response.data.isValidAccess);  
        } else {
          setIsLoading(false);
          setIsValidMeet(false);
        }
         
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsValidMeet(false);
      });
  }, []);

  const isValidToken = async () => {
    let data = JSON.stringify({
      "meetingId": meetingId.includes("M-") ? meetingId.slice(2) : meetingId
    })
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API}/api/v1/schedule/is-valid-access`,
      headers: {
        Authorization: authHeader ? accessToken : null,
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios.request(config);
  };

  const updateMeetingStatus = async () => {
    let data = JSON.stringify({
      "meetingId": meetingId.includes("M-") ? meetingId.slice(2) : meetingId,
      "meetingStatus": "Cancelled"
    })
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API}/api/v1/schedule/update-meeting-status`,
      headers: {
        Authorization: authHeader ? accessToken : null,
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios.request(config);
  };

  let moderatorToolbarButtons = [
    "camera",
    "chat",
    "closedcaptions",
    "desktop",
    "download",
    //"embedmeeting",
    "etherpad",
    "feedback",
    "filmstrip",
    "fullscreen",
    "hangup",
    "help",
    "highlight",
    //"invite",
    "linktosalesforce",
    "livestreaming",
    "microphone",
    "noisesuppression",
    "participants-pane",
    "profile",
    "raisehand",
    "recording",
    "security",
    "select-background",
    "settings",
    "shareaudio",
    "sharedvideo",
    "shortcuts",
    "stats",
    "tileview",
    "toggle-camera",
    "videoquality",
    //"whiteboard",
  ];
  let participantToolbarButtons = [
    "camera",
    "chat",
    "microphone",
    "noisesuppression",
    "raisehand",
    "select-background",
    "tileview",
    "toggle-camera",
    "videoquality",
    "hangup",
  ];

  const printEventOutput = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
  };

  //   const handleModerator = (iD) => {
  //     apiRef.current.executeCommand('grantModerator', iD);
  //   }

  const handleAudioStatusChange = (payload, feature) => {
    if (payload.muted) {
      updateLog((items) => [...items, `${feature} off`]);
    } else {
      updateLog((items) => [...items, `${feature} on`]);
    }
  };

  const handleChatUpdates = (payload) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    apiRef.current.executeCommand("toggleChat");
    updateLog((items) => [
      ...items,
      `you have ${payload.unreadCount} unread messages`,
    ]);
  };

  const handleKnockingParticipant = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants) => [
      ...participants,
      payload?.participant,
    ]);
  };

  /* const resolveKnockingParticipants = (condition) => {
    knockingParticipants.forEach((participant) => {
      apiRef.current.executeCommand(
        "answerKnockingParticipant",
        participant?.id,
        condition(participant)
      );
      updateKnockingParticipants((participants) =>
        participants.filter((item) => item.id === participant.id)
      );
    });
  }; */

  const handleJitsiIFrameRef1 = (iframeRef) => {
    // iframeRef.style.border = '10px solid #3d3d3d';
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "100%";
    // iframeRef.style.marginBottom = '20px';
  };

  /* const handleJitsiIFrameRef2 = (iframeRef) => {
    iframeRef.style.marginTop = "10px";
    iframeRef.style.border = "10px dashed #df486f";
    iframeRef.style.padding = "5px";
    iframeRef.style.height = "400px";
  };

  const handleJaaSIFrameRef = (iframeRef) => {
    iframeRef.style.border = "10px solid #3d3d3d";
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "400px";
    iframeRef.style.marginBottom = "20px";
  }; */

  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;
    apiRef.current.executeCommand('displayName', userName);

    /* ************************************************************************************** */
    //Email set with role prefix to identify user-roles set from portal api
    //DO NOT Edit
    /* const localEmailPrefix = isModerator?"mod":"seek";
    const localEmail = `${localEmailPrefix}___${userName.replace(/\s+/g, "").toLowerCase()}@xceptionallearningindia.com`;
    apiRef.current.executeCommand('email', localEmail); */

    /* apiRef.current.on("audioAvailabilityChanged", (payload) => {
      if(!moderatorFromApi){
        handleJitsiRoleChange();
      }
    }); 
    apiRef.current.on("participantJoined", (payload) => {
      console.log("oooooo participantJoined - Role changes oooooooo", moderatorFromApi, payload);
      if(!moderatorFromApi){
        handleJitsiRoleChange();
      }
    });*/
    /* apiRef.current.on("participantLeft", (payload) => {
      if(!moderatorFromApi){
        handleJitsiRoleChange();
      }
    }); */ 
    /* apiRef.current.on("participantJoined", (payload) => {
      if(!moderatorFromApi){
        handleJitsiRoleChange();
      }
    }); */
    /* ************************************************************************************** */


    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
    apiRef.current.on("audioMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "audio")
    );
    
    


    apiRef.current.on("videoMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "video")
    );

    apiRef.current.on("participantRoleChanged", (payload) => {
      setLocalParticipantId(payload.id);
      if(payload.role === 'moderator'){
        if(!moderatorFromApi){
          handleJitsiRoleChange(payload);
        }
        else{
          setRoleToolbar('moderator')
        }
      }
      else{
        setRoleToolbar('participant')
      }
    });
    /* Change Toolbar when local participant role changed to moderator
    apiRef.current.on("participantRoleChanged", (payload) => {
      console.log("role changed", payload);
      if(payload.role === 'moderator') {
        setIsModerator(true)
        apiRef.current.executeCommand('overwriteConfig',
          {
            toolbarButtons: moderatorToolbarButtons
          }
        );
      }
      else{
        if(!moderatorFromApi){
          setIsModerator(false);
          apiRef.current.executeCommand('overwriteConfig',
            {
              toolbarButtons: participantToolbarButtons
            }
          );
        }
      } 
    });*/
    apiRef.current.on("raiseHandUpdated", printEventOutput);
    apiRef.current.on("titleViewChanged", printEventOutput);
    apiRef.current.on("chatUpdated", handleChatUpdates);
    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
    apiRef.current.on("toolbarButtonClicked", handleToolbarButtonClicked);
  };

  const setRoleToolbar = (role) => {
    const isMod = role==='moderator'?true:false;
    const tools = isMod ? moderatorToolbarButtons : participantToolbarButtons;
    setIsModerator(isMod)
    apiRef.current.executeCommand('overwriteConfig', { toolbarButtons: tools });
  }
  const handleJitsiRoleChange = (payload) => {
    if(moderatorFromApi === false){
      apiRef.current.getRoomsInfo().then((roomsInfo) => {
        const mainRoom = roomsInfo.rooms.find(obj => obj.isMainRoom === true);
        //console.log("=================================main room========================================", mainRoom);
        if(mainRoom.participants?.length <= 1){
          setShowWaitForModerator(true);
          startWaitingTimer(30);
        }
        else{
          let hasModerator = false;
          let modName = "";
          console.log(mainRoom.participants);
          for (let i = 0; i < mainRoom.participants.length; i++) {
            var user = mainRoom.participants[i];
            if(payload.id != user.id && user.role === "moderator"){
              hasModerator = true;
              modName = user.displayName;
              break;
            }
          }
          if(!hasModerator){
            setShowWaitForModerator(true);
            startWaitingTimer(30);
          }
          else{
            setShowWaitForModerator(false);
            setRoleToolbar('moderator');
          }
        }
      });
    }
    else{
      setShowWaitForModerator(false);
    }
  };

  const updateTimer = (seconds) => {
    if(document.getElementById('waitForModTime')){
      document.getElementById('waitForModTime').textContent = seconds;
    }
  }
  const startWaitingTimer = (seconds) => {
    updateTimer(seconds);
    if (seconds > 0) {
      setTimeout(function() {
        startWaitingTimer(seconds - 1);
      }, 1000);
    } 
    else{
      location.reload();
    }
  }

  const handleHangupConfirm = () => {
    if(isModerator){
      apiRef.current.executeCommand('endConference');
    }
    apiRef.current.executeCommand('hangup');
    setIsHangupModalVisible(false);
  };

  const handleHangupCancel = () => {
    setIsHangupModalVisible(false);
  };

  const handleToolbarButtonClicked = (obj) => {
    if(obj.key === 'hangup' || obj.key === 'end-meeting'){
      let leaveMeetingAction = obj.key==="end-meeting"?"end":"leave";
      setLeaveMeetingAction(leaveMeetingAction);
      setIsHangupModalVisible(true);
    }
    return false;
  };

  const handleReadyToClose = () => {
    /* eslint-disable-next-line no-alert */
    if (isModerator) {
      updateMeetingStatus()
      .then((response) => {
        apiRef.current.executeCommand("endConference");
        setTimeout(() => {
          window.location.replace(
            `${PORTAL_ROOT}/dashboard`
          );
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      setTimeout(() => {
        window.location.replace(
          `${PORTAL_ROOT}/dashboard`
        );
      }, 1000);
    }
  };

  const renderSpinner = () => (
    <div
      style={{
        fontFamily: "sans-serif",
        textAlign: "center",
      }}
    >
      Loading..
    </div>
  );

  const invalidMeet = (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <span style={{ fontSize: "xxx-large" }}> {isLoading ? "Loading....." : "Invalid Meeting"} </span>
    </div>
  );

  const validMeet = (
    <JitsiMeeting
      domain={JITSIDOMAIN}
      roomName={meetingId?.includes("M-") ? meetingId?.slice(2) : meetingId}
      spinner={renderSpinner}
      configOverwrite={{
        /* toolbarConfig:{
          alwaysVisible:true
        }, */
        prejoinConfig: {
          enabled: userName.length > 0 ? false : true,
        },
        deeplinking: {
          disabled: true,
        },
        startWithVideoMuted: true,
        subject: meetingName,
        hideConferenceSubject: false,
        toolbarButtons: isModerator
          ? moderatorToolbarButtons
          : participantToolbarButtons,
        buttonsWithNotifyClick: [
            {
              key: 'hangup',
              preventExecution: true
            },
            {
              key: 'end-meeting',
              preventExecution: true
            },
          ]
      }}
      onApiReady={(externalApi) => handleApiReady(externalApi)}
      onReadyToClose={handleReadyToClose}
      getIFrameRef={handleJitsiIFrameRef1}
    />
  );

  const updateHyperbeamUrl = async (url, hbComputerInstance) => {
    let hbComputerSession = hbComputerInstance ?? hbComputer;
    if(hbComputerSession){
      if(!hbEmbed){
        await Hyperbeam(hyperbeamComputerDiv, hbComputerSession.embed_url, {
          adminToken: hbComputerSession.admin_token,
          videoPaused: false, 
          timeout: 30000
        }).then(function (hbEmbed) {
          setHbEmbed(hbEmbed);
          hbEmbed.tabs.update({ url: url })
          apiRef.current.executeCommand('startShareVideo', `${hbComputerSession.embed_url}/XLSharedApp_Iframe`);
          setHbSessionLoading(false);
        })
      }
      else{
        hbEmbed.tabs.update({ url: url })
        apiRef.current.executeCommand('startShareVideo', `${hbComputerSession.embed_url}/XLSharedApp_Iframe`);
        setHbSessionLoading(false);
      }
    }
  }

  const startHBCollaborate = async (url) => {
    if(hbComputer === null){
      setHbSessionLoading(true);
      await axios.get(`${HB_API_ROOT}/computer`)
        .then(function (response) {
          if(response.data?.session_id){
            setHbComputer(response.data);
            (async () => {
              setIsCollaborate(true)
              await updateHyperbeamUrl(url, response.data);
            })();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else{
      (async () => {
        setIsCollaborate(true)
        await updateHyperbeamUrl(url, hbComputer);
      })();
    }
  }

  const stopHBCollaborate = async () => {
    await axios.post(`${HB_API_ROOT}/end-session`, { sessionId : hbComputer.session_id})
      .then(function (response) {
        if(response.data?.session_id){
          console.log("HB session closed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    apiRef.current.executeCommand('stopShareVideo');
    setIsCollaborate(false)
    setHbComputer(null);
    setHbEmbed(null);
  }

  const startVideoContentSharing = async (url) => {
    apiRef.current.executeCommand('startShareVideo', `${url}`);
    setIsVideoContentShared(true);
  }
  const stopVideoContentSharing = async () => {
    apiRef.current.executeCommand('stopShareVideo');
    setIsVideoContentShared(false);
  }

  return (
    <>
      {showWaitForModerator && (<div className="waitForModerator">
          <div className="message">
            <h2>Please wait while the moderator joins...</h2>
            <h1>Retrying in <span id="waitForModTime">30</span> seconds</h1>
          </div>
        </div>)}
      <div id="hyperbeamComputerDiv"></div>
      {hbSessionLoading && <div className="loadingSpinnerWrapper"><LoadingOutlined className="loadingSpinner" /></div>}
      {isValidMeet && isModerator && !showWaitForModerator? 
        <Sidebar 
        isCollaborate={isCollaborate} 
        stopHBCollaborate={stopHBCollaborate} 
        updateHyperbeamUrl={updateHyperbeamUrl}
        startHBCollaborate={startHBCollaborate} 
        isVideoContentShared={isVideoContentShared} 
        startVideoContentSharing={startVideoContentSharing}
        stopVideoContentSharing={stopVideoContentSharing}
        isOpen={isSidebarOpen} 
        toggleSidebar={toggleSidebar} 
        accessToken={accessToken}
        meetingId={meetingId.includes("M-") ? meetingId.slice(2) : meetingId}
        /> 
        : null}
      {isValidMeet && !showWaitForModerator ? validMeet : invalidMeet}

      <Modal
        title={`Confirm ${leaveMeetingAction}-meeting`}
        open={isHangupModalVisible}
        onOk={handleHangupConfirm}
        onCancel={handleHangupCancel}
      >
        {isModerator && <p>{"This action may end this meeting for all participants."}</p>}
        <p>{`Do you really want to ${leaveMeetingAction} this meeting?`}</p>
      </Modal>
    </>
  );
};

export default Room;
