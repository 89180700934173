export const JITSIDOMAIN = "nvc2.xceptionallearningindia.com";
export const API = "https://sche-staging.api.xceptionallearningindia.com";
export const CONTENT_API_ROOT="https://cdn-staging.api.xceptionallearningindia.com";
export const SCHEDULE_API_ROOT="https://sche-staging.api.xceptionallearningindia.com/api/v1/schedule";
export const HB_API_ROOT = "https://misc-staging.api.xceptionallearningindia.com/api/v1/hyperbeam";
//export const CONTENT_API_ROOT="http://localhost:4002";
//export const HB_API_ROOT = "http://localhost:4007/api/v1/hyperbeam";
export const VCAPI_ROOT = "https://vcapi23.xceptionallearning.com";
export const WHITEBOARD_APP_ROOT = "https://app.whiteboard.xceptionallearningindia.com";
export const YOUTUBE_API_KEY = "AIzaSyDg7NxG_f1QD-gprDkdop53NaKv7sPUihA";
export const PORTAL_ROOT = "https://portal-staging.xceptionallearningindia.com";