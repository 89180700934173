// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pagination-container {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.pagination {\n  display: flex;\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n}\n\n.pagination-item {\n  cursor: pointer;\n  padding: 5px 10px;\n  margin: 0 2px;\n  border-radius: 3px;\n  background-color: #fff;\n  color: #333;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.pagination-item:hover {\n  background-color: #333;\n  color: #fff;\n}\n\n.pagination-item.active {\n  background-color: #333;\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/components/ContentPaginationStyles.css"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,6CAA6C;AAC/C;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');\n.pagination-container {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.pagination {\n  display: flex;\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n}\n\n.pagination-item {\n  cursor: pointer;\n  padding: 5px 10px;\n  margin: 0 2px;\n  border-radius: 3px;\n  background-color: #fff;\n  color: #333;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.pagination-item:hover {\n  background-color: #333;\n  color: #fff;\n}\n\n.pagination-item.active {\n  background-color: #333;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
